import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider } from "@material-ui/core"
import { ThemeProvider } from '@mui/material'
import theme from "./theme"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from '@date-io/moment'
import newTheme from "./newTheme"
import { BrowserTracing } from "@sentry/tracing"
import * as Sentry from "@sentry/react"
import Fallback from "./Shared/Loader/Fallback"
import ErrorBoundary from "./ErrorBoundary"
import i18n from "./Translation/i18n"
import { I18nextProvider } from "react-i18next"

const App = React.lazy(() => import("./App"))

Sentry.init({
    dsn: "https://3affafefd8a241f0bbfc07a29220ea04@o4504441057968128.ingest.sentry.io/4504441060720640",
    integrations: [
        new BrowserTracing(),
        new Sentry.Integrations.Breadcrumbs({ console: false }),
        new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // tracesSampleRate: 0.5,
    beforeSend: (event) => {
        console.log(event)
        if (event.level === "fatal") return event
        return null
    },
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
})

ReactDOM.render(
    <ThemeProvider theme={newTheme}>
        <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <I18nextProvider i18n={i18n}>
                    <BrowserRouter>
                        <Sentry.ErrorBoundary
                            fallback={({ error, componentStack, resetError }) => (
                                <ErrorBoundary error={error} info={componentStack} />
                            )}
                            beforeCapture={scope => {
                                scope.setLevel("fatal")
                            }}
                        >
                            <Suspense fallback={<Fallback />}>
                                <App />
                            </Suspense>
                        </Sentry.ErrorBoundary>
                    </BrowserRouter>
                </I18nextProvider>
            </MuiPickersUtilsProvider>
        </MuiThemeProvider>
    </ThemeProvider>,
    document.getElementById("root")
)
