import { Box } from '@material-ui/core'
import { Typography } from '@mui/material'
import newTheme from '../../newTheme'
import { useTranslation } from 'react-i18next'

const css = {
	container: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
		background: newTheme.palette.background.main
	},
}

function Fallback({ }) {
	const { t } = useTranslation('loader')

	document.getElementById('fallback').style.display = 'none'

	return (
		<Box sx={css.container}>
			<Typography variant='h4' color="primary.main">{t('loading')}</Typography>
		</Box>
	)
}

export default Fallback