import { CheckCircle, DateRange, Event, FormatAlignCenter, FormatListBulleted, FormatListNumbered, HelpOutline, ImageOutlined, LooksOneOutlined, PersonPin, TextFormat, DescriptionOutlined } from '@material-ui/icons'
import { CalculateOutlined, Engineering, Group, LocationOnOutlined } from '@mui/icons-material'

export function fieldTypes(t) {
	return (
		[
			{ name: t("drawer.types.text"), value: "text", icon: TextFormat },
			{ name: t("drawer.types.selectOption"), value: "select", icon: FormatListBulleted },
			{ name: t("drawer.types.multiSelect"), value: "multiselect", icon: FormatListNumbered },
			{ name: t("drawer.types.image"), value: "img", icon: ImageOutlined },
			{ name: t("drawer.types.date"), value: "date", icon: Event },
			{ name: t("drawer.types.number"), value: "number", icon: LooksOneOutlined },
			{ name: t("drawer.types.richText"), value: "rte", icon: FormatAlignCenter },
			{ name: t("drawer.types.rut"), value: "rut", icon: TextFormat },
			{ name: t("drawer.types.duration"), value: "duration", icon: DateRange },
			{ name: t("drawer.types.compliance"), value: "compliance", icon: CheckCircle },
			{ name: t("drawer.types.ids"), value: "ids", icon: HelpOutline },
			{ name: t("drawer.types.location"), value: "location", icon: LocationOnOutlined },
			{ name: t("drawer.types.user"), value: "users", icon: PersonPin },
			{ name: t("drawer.types.multiUsers"), value: "multi_users", icon: Group },
			{ name: t("drawer.types.contractor"), value: "contractor", icon: Engineering },
			{ name: t("drawer.types.multiContractors"), value: "multi_contractors", icon: Engineering },
			{ name: t("drawer.types.calculation"), value: "calc", icon: CalculateOutlined },
			{ name: t("drawer.types.document"), value: "document", icon: DescriptionOutlined },
		]
	)
}
