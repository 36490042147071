import moment from "moment"
import TagCell from "../../Shared/TableRenders/TagCell"
import { getLocalCrits } from "../../Utils/functions"
import CommitteeCell from "../../Shared/TableRenders/CommitteeCell"
import FindingImages from "./FindingImages"
import { fieldTypes } from "../Settings/BaseChecklists/Show/Form/info"

export function criticalityFormat(level, t) {
	const crits = getLocalCrits()
	const crit = crits.find(c => c.value === level)
	if (!crit) return t('finding.criticality.notAssigned')
	return crit.label
}

const criticalityDeFormat = (level) => {
	const crits = getLocalCrits()
	const crit = crits.find(c => c.label === level)
	if (!crit) return 0
	return crit.value
}

export function calculate_late_days(value, element, t) {
	if (value === "0000-00-00") return "-"
	if (["Terminado", "Aprobado"].includes(element.status)) {
		const total = moment(element.date_solved).diff(moment(element.date_limit), "days")
		return total > 0 ? `${total}` : "0 "
	}
	const total = moment().diff(moment(element.date_limit), "days")
	return total > 0 ? `${total}` : "0 "
}

export function calculate_late_days_number(value, element) {
	if (value === "0000-00-00") return "-"
	if (["Terminado", "Aprobado"].includes(element.status)) {
		const total = moment(element.date_solved).diff(moment(element.date_limit), "days")
		return total > 0 ? total : 0
	}
	const total = moment().diff(moment(element.date_limit), "days")
	return total > 0 ? total : 0
}

function calculate_speed(value, element) {
	if (element.date_solved === "0000-00-00") return "-"
	return `${moment(element.date_solved).diff(moment(element.date_assigned), "days")} días`
}

export function calculate_speed_number(value, element) {
	if (element.date_solved === "0000-00-00") return 0
	return moment(element.date_solved).diff(moment(element.date_assigned), "days")
}

function processImages(value, element) {
	if (!value) return "-"
	const images = value.split("&#&") || []
	if (!images.length) return "-"
	return images.join(" ")
}

export function tableInfo(t) {
	return [
		{ name: t('finding.data.branch'), label: "branch_name" },
		{ name: t('finding.data.checklistName'), label: "base_checklist_name", format: (v) => v || "-" },
		{ name: t('finding.data.location'), label: "subdivision_name", format: (v) => v || "-" },
		{ name: t('finding.data.dateDetected'), label: "date_detected", format: (v) => moment(v).format("DD-MM-YYYY") },
		{ name: t('finding.data.tags'), label: "tags", render: TagCell },
		{ name: t('finding.data.userDetector'), label: "user_creator" },
		{ name: t('finding.data.item'), label: "item", format: (v) => v || t('finding.data.freeFinding') },
		{ name: t('finding.data.description'), label: "name", render: CommitteeCell },
		{ name: t('finding.data.inmediateAction'), label: "inmediate_action" },
		{ name: t('finding.data.userResponsible'), label: "user_responsible" },
		{ name: t('finding.data.userSolver'), label: "user_solver" },
		{ name: t('finding.data.dateAssigned'), label: "date_assigned" },
		{ name: t('finding.data.dateApproved'), label: "date_approved" },
		{ name: t('finding.data.dateLimit'), label: "date_limit" },
		{ name: t('finding.data.dateCompliance'), label: "date_solved" },
		{ name: t('finding.data.daysLate'), label: "late_days", format: (v, element) => calculate_late_days(v, element, t) },
		{ name: t('finding.data.resolutionSpeed'), label: "speed", format: calculate_speed },
		{ name: t('finding.data.status'), label: "status" },
		{ name: t('finding.data.baseFindingActionGroups'), label: "base_finding_action_groups" },
		{ name: t('finding.data.criticality'), label: "criticality", format: (v) => criticalityFormat(v, t), unformat: criticalityDeFormat },
		{ name: t('finding.data.approvalComments'), label: "approval_comment" },
		{ name: t('finding.data.predefinedDescription'), label: "base_action", format: (v) => v || t('finding.criticality.notAssigned') },
		{ name: t('finding.data.evidenceImages'), label: "evidence", render: FindingImages, export: processImages },
		{ name: t('finding.data.solutionImages'), label: "solution_evidence", render: FindingImages, export: processImages },
		{ name: t('finding.data.findingType'), label: "finding_type" },
	]
}

export function filters(t) {
	return [
		{ name: t('finding.data.userResponsible'), label: "user_responsible" },
		{ name: t('finding.data.userDetector'), label: "user_creator" },
		{ name: t('finding.data.status'), label: "status" },
		{ name: t('finding.data.checklistName'), label: "base_checklist_name" },
		{ name: t('finding.data.criticality'), label: "criticality", format: (v) => criticalityFormat(v, t), unformat: criticalityDeFormat },
	]
}

export function sortables(t) {
	return [
		{ name: t('finding.data.dateLimit'), label: "date_limit" },
		{ name: t('finding.data.dateDetected'), label: "date_detected" }
	]
}

export function setupForm(all_fields) {
	function transform_to_json_field(fields) {
		const json_fields = fields.map(field => {
			const json_field = field
			const json_options = field.options.map(option => {
				const json_option = option
				const subquestions = all_fields.filter(field => field.field_option_id === option.id)
				json_option.subquestions = transform_to_json_field(subquestions)
				return json_option
			})
			json_field.options = json_options
			return json_field
		})
		return json_fields
	}
	const processed_root_fields = all_fields.filter(field => !field.field_option_id)
	return transform_to_json_field(processed_root_fields)
}

export function getFieldTypeNameFromValue(value, t) {
	const field_type = fieldTypes(t).find(field_type => field_type.value === value)
	if (!field_type) return "No asignado"
	return field_type.name
}

export function getStatus(finding) {
	if (finding.user_id === 0 || !finding.user_id) return "Pendiente"
	return "Procesando"
}

export const STATUS = {
	PENDING: "Pendiente",
	PROCESS: "Procesando",
	OVERDUE: "Vencido",
	FINISHED: "Terminado",
	APPROVED: "Aprobado",
	REJECTED: "Rechazado",
	REESTIMATE: "Re-estimado",
	DISMISSED: "Desestimado",
	DELETED: "Eliminado"
}