import { KeyboardArrowRight } from '@mui/icons-material'

const css = {
  icon: {
    transition: "all 0.2s linear",
  },
  open: {
    transform: "rotate(90deg)",
  }
}

function ToggleIcon({ open }) {
  return (
    <KeyboardArrowRight sx={{ ...css.icon, ...(open ? css.open : "") }} />
  )
}

export default ToggleIcon