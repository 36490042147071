import { uploadFile } from "../../../API/users"
import { resizeImageAndGetFile } from "../../../Utils/functions"

export default class MyUploadAdapter {
  constructor(loader, uploaded) {
    this.loader = loader
    this.uploaded = uploaded
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then(file => new Promise((resolve, reject) => {

      const callback = async (image, newFile) => {
        const body = new FormData()
        body.append("file", newFile)
        body.append("name", file.name)
        body.append("path", "RTE")
        try {
          const response = await uploadFile(body)
          if (response.data.status === "success") {
            const returned_url = response.data.info
            resolve({ default: returned_url })
            // Call the uploaded function, which is a property of this class
            if (this.uploaded) {
              this.uploaded(returned_url)
            }
          } else {
            reject(`Couldn't upload file: ${file.name}.`)
          }
        } catch (error) {
          console.error("Upload failed", error);
          reject(`Couldn't upload file: ${file.name}.`)
        }
      }

      // Call resizeImageAndGetFile and pass the modified callback
      resizeImageAndGetFile(file, callback);
    }))
  }
}
