import { Box, Typography } from '@mui/material'
import { info_box_grey, info_box_white, inline_buttons, italic } from '../../../../Utils/defaultStyles'
import { AccountCircleOutlined, CalendarToday, ErrorOutline, FormatListNumbered, InfoOutlined, NotesOutlined, PersonPinCircleOutlined, Place, ScheduleOutlined } from '@material-ui/icons'
import moment from 'moment'
import ExtraFieldsDialog from '../ExtraFieldsDialog'
import useToggle from '../../../../Hooks/ToogleHook'
import { useContext } from 'react'
import { UserSelectContext } from '../../../../Shared/Dialogs/UserSelectDialog'
import ChatIcon from '@mui/icons-material/Chat'
import { InfoRow, ImageSection } from './CardInfoLayouts'
import { useTranslation } from 'react-i18next'

function MainInfo({ finding, crit, urls, updateForm }) {

	const { getUsersNames } = useContext(UserSelectContext)

	let images = finding?.free_file?.split("&#&") || []
	if (!images.length) images = finding?.evidence?.split("&#&") || []
	const isAssigned = finding?.user_id > 0
	const [openDialog, toggleDialog] = useToggle(false)
	const inmediate_action = finding?.inmediate_action || finding?.description
	const hasImages = !!images.length
	const responsible = finding?.user_responsible || getUsersNames(finding?.user_id)
	const description = finding?.base_action
	const { t } = useTranslation("findingCardInfo")
	return (
		<Box>
			<Box sx={info_box_grey}>
				<InfoRow icon={ErrorOutline} value={crit?.label} color={crit?.color} />
				<InfoRow icon={AccountCircleOutlined} label={t('data.detectorUser')} value={finding?.user_creator} />
				<InfoRow icon={ScheduleOutlined} label={t('data.dateDetection')} value={moment(finding?.date_detected).format("DD-MM-YYYY HH:mm")} />
				<InfoRow icon={Place} label={t('data.branch')} value={finding?.branch} />
				<InfoRow icon={PersonPinCircleOutlined} label={t('data.responsible')} value={responsible || t('labels.pending')} color={isAssigned ? "primary" : "warning.main"} />
				<InfoRow icon={ChatIcon} label={t('data.description')} value={description || "-"} />
				<InfoRow icon={CalendarToday} label={t('data.dateLimit')} value={isAssigned ? moment(finding?.date_limit).format("DD-MM-YYYY HH:mm") : t('labels.pending')} color={isAssigned ? "primary" : "warning.main"} />
				{finding?.counter > 1 &&
					<InfoRow icon={FormatListNumbered} label={t('data.counter')} value={finding?.counter || 1} />
				}
				{!!finding?.form &&
					<Box sx={{ ...inline_buttons, margin: '0 0 3px 0', cursor: 'pointer' }} onClick={toggleDialog}>
						<InfoOutlined />
						<Typography variant='subtitle2' color="info.main" sx={italic}>{t('data.additionalInfo')}</Typography>
					</Box>
				}
				{!!inmediate_action &&
					<Box>
						<InfoRow icon={NotesOutlined} label={t('data.inmediateAction')} />
						<Box sx={info_box_white}>
							<Typography variant='subtitle1'>{inmediate_action}</Typography>
						</Box>
					</Box>
				}
			</Box>
			{hasImages &&
				<ImageSection images={images} baseUrl={urls[1]} alt={t('alt')} />
			}
			<ExtraFieldsDialog finding={finding} open={openDialog} onClose={toggleDialog} crit={crit} urls={urls} onSubmit={updateForm} />
		</Box>
	)
}

export default MainInfo