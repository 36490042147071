import { Tooltip, Typography } from '@mui/material'
import { Warning } from '@mui/icons-material'

const css = {
	uninteractive_text: {
		pointerEvents: 'none',
		userSelect: 'none',
		padding: 1,
		display: 'flex',
		alignItems: 'center'
	}
}

function BigTooltip({ title, children, disabled = false, isWarning = false }) {

	if (disabled || !title) {
		return <span>{children}</span>
	}

	const tooltipContent = (
		<Typography
			sx={css.uninteractive_text}
			variant="subtitle1"
			color={isWarning ? 'orange.main' : 'white.main'}
		>
			{isWarning && <Warning sx={{ mr: 1 }} />}
			{title}
		</Typography>
	)

	return (
		<Tooltip placement="right" title={tooltipContent}>
			<span>{children}</span>
		</Tooltip>
	)
}

export default BigTooltip
