import useToggle from '../../Hooks/ToogleHook'
import { Box, Typography } from '@mui/material'

const css = {
  tag: {
    borderRadius: 6,
    height: 12,
    width: 12,
    margin: 1,
    padding: 1,
    transition: 'width 0.2s linear, height 0.1s linear 0.2s',
    overflow: 'hidden',
    '& > *': {
      opacity: 0,
      transition: 'all 0.2s linear'
    },
    cursor: 'pointer'
  },
  open: {
    width: '100%',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    height: 18,
    '& > *': {
      transition: 'all 0.2s linear 0.3s',
      color: 'white',
      fontWeight: 600,
      opacity: 1,
      padding: 2,
    }
  }
}

export default function InnerTag({ tag }) {
  const [openTag, toggleOpenTag] = useToggle(false)


  if (!tag) return null

  return (
    <Box sx={{ ...css.tag, ...(openTag ? css.open : ""), background: tag.color }} onClick={toggleOpenTag}>
      <Typography variant='subtitle1' color="white.main" >{tag.name}</Typography>
    </Box>

  )
}
