import axios from "axios"
import { loginEnterprise, logout, renewUserToken } from "../API/login"

const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
)

const isDev = window.location.hostname.includes("dev")

const isNewServer = !window.location.origin.includes("safeasy.cl")

const productionURL = isNewServer ? window.location.origin : process.env.REACT_APP_PRODUCTION_URL
let baseUrl = isLocalhost ? 'http://localhost:3000/apiv1' : `${productionURL}/apiv1`
// let baseUrl = "https://tazkiapp.com/apiv1"
if (isDev) baseUrl = `${productionURL}/apiv2`
export const url = baseUrl

export const webSocketUrl = isLocalhost ? 'ws://localhost:3000/apiv1/cable' : `${productionURL.replace("http", "ws")}/apiv1/cable`


function shouldUpdate(v1, v2) {
    const v1Split = v1.split(".")
    const v2Split = v2.split(".")
    if (v1Split[0] > v2Split[0]) {
        return "major"
    } else if (v1Split[0] === v2Split[0]) {
        if (v1Split[1] > v2Split[1]) {
            return "minor"
        } else if (v1Split[1] === v2Split[1]) {
            if (v1Split[2] > v2Split[2]) {
                return "patch"
            }
        }
    }
    return false
}

function handleKickout(info) {
    if (!info) return
    if (!info.kickout) return
    // If kickout is true, then the user is kicked out of the app
    if (window.location.href.includes("kickout")) return
    window.location.href = window.location.origin + "/kickout"
}

function handleExpiredToken() {
    const loggedIn = localStorage.getItem("login") === "true"
    if (!loggedIn) return
    localStorage.setItem("login", "false")
    const redirection = new URL(window.location.origin)
    redirection.searchParams.set("expired", "true")
    window.history.pushState({}, "", redirection.href)
    logout()
}

function handleOldSession() {
    localStorage.setItem("login", "false")
    localStorage.setItem("account", null)
    const redirection = new URL(window.location.origin)
    redirection.searchParams.set("expired", "true")
    window.history.pushState({}, "", redirection.href)
    logout()
}

async function renewToken() {

    const renewing = localStorage.getItem("renewing")
    if (renewing) return
    const currentUserData = localStorage.getItem("current_user")
    const currentUser = currentUserData ? JSON.parse(currentUserData) : null

    if (!currentUser) return handleExpiredToken()

    const response = await renewUserToken({ user_id: currentUser.id })
    localStorage.setItem("renewing", "true")
    if (!response) {
        localStorage.removeItem("renewing")
        return handleExpiredToken()
    }

    localStorage.setItem("login", "true")
    const token = response.data.info.jwt
    localStorage.setItem("token", token)
    localStorage.setItem("account", JSON.stringify(response.data.info.user))
    localStorage.removeItem("renewing")
    window.location.reload()
}

async function handleUpdate(latestVersion, updateType) {
    localStorage.setItem("version", latestVersion)

    if (updateType === "patch") {
        // Delete Cache
        await caches.delete("cache-tazki")

        // Remove service workers
        const registrations = await navigator.serviceWorker.getRegistrations()
        if (registrations?.length > 0) {
            for (let registration of registrations) {
                await registration.unregister()
            }
        }

        // Register new service worker
        await navigator.serviceWorker.register("/sw.js")

        // Create a new cache
        const cache = await caches.open("cache-tazki")

        // Fetch new files and add them to the cache
        const index = await fetch("/index.html")
        cache.put("/index.html", index)

        console.log("Actualizado")
    }

    if (updateType === "minor" || updateType === "major") {
        window.location.href = window.location.origin + "/update/update.html"
    }
}

const instance2 = axios.create({
    baseURL: url,
    timeout: 120000,
})

instance2.interceptors.request.use((config) => {
    const newConfig = { ...config }
    const filtersData = localStorage.getItem("filters")
    const filters = filtersData ? JSON.parse(filtersData) : []
    const accessToken = localStorage.getItem("token") || ""
    const account = localStorage.getItem("account") ? JSON.parse(localStorage.getItem("account")) : null
    const userId = account?.id
    const companyId = account?.idCompany
    const branch_id = localStorage.getItem("branch_id") || ""
    newConfig.headers["Token"] = accessToken
    newConfig.headers["User-Id"] = userId
    newConfig.headers["Company-Id"] = companyId
    newConfig.headers["Branch-Id"] = branch_id
    newConfig.headers["Branch-Ids"] = branch_id
    newConfig.headers["filters"] = filters
    newConfig.headers["baseurl"] = window.location.origin
    return config
})

instance2.interceptors.response.use(
    response => {
        const currentBrowserVersion = localStorage.getItem("version") || "1.0.0"

        if (currentBrowserVersion === "1.0.0") { // First time the app is opened
            localStorage.setItem("version", response.data.version)
            return response
        }

        if (!response.data?.version) return response
        handleUpdate(response.data.version, shouldUpdate(response.data.version, currentBrowserVersion))
        handleKickout(response.data)

        // Check if needed to refresh token
        if (!!response.data?.refreshed_token) { localStorage.setItem("token", response.data.refreshed_token) }

        return response
    }, error => {
        // if (error.response?.status == 401) { handleUnauthorized() }
        if (error.response?.status === 422) {
            if (error.response.data?.message === "Signature has expired") { return handleOldSession() }
            if (error.response.data?.message === "Signature verification failed") { return renewToken() }
            handleExpiredToken()
        }
        return error
    }
)

export default instance2
