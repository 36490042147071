import { Box, Button, Dialog, Divider, IconButton, Typography } from '@mui/material'
import { end_buttons, full_dialog_style, inline_space_title, text_space, title_style } from '../../../Utils/defaultStyles'
import { Clear, PersonAddOutlined } from '@material-ui/icons'
import MainInfo from './CardInfo/MainInfo'
import { useEffect, useState } from 'react'
import FindingFormRender from '../Fields/FindingFormRender'
import { setupForm } from '../Utils'
import { transformOfflineRequestToOnlinePlain } from '../../../Utils/functions'
import { useTranslation } from 'react-i18next'
/**
 * Transforms a finding form to plain format.
 * @param {Array} form - The finding form to transform.
 * @returns {Promise<Array>} - The transformed finding form in plain format.
 */
export async function transformFindingFormToPlain(form) {
	const new_form = []
	for (let field of form) {
		const new_field = { ...field }
		const new_plain_field = await transformOfflineRequestToOnlinePlain(new_field)
		if (new_field.field_type === "select") {
			const new_options = [...new_field.options]
			const selected_option = new_options.find(op => op.label === new_field.value)

			// If there is no selected option, we don't need to transform the subquestions
			if (!selected_option) {
				new_form.push(new_plain_field)
				continue
			}

			const selected_option_index = new_options.findIndex(op => op.label === new_field.value)
			const subquestions = selected_option?.subquestions ? [...selected_option?.subquestions] : []
			const new_subquestions = await transformFindingFormToPlain(subquestions)
			selected_option.subquestions = new_subquestions
			new_options[selected_option_index] = selected_option
			new_plain_field.options = new_options
		}
		new_form.push(new_plain_field)
	}
	return new_form
}

function ExtraFieldsDialog({ finding, open, onClose, crit, urls, onSubmit }) {

	const [form, setForm] = useState([])
	const { t } = useTranslation("findingCard")

	useEffect(() => {
		let form = []
		try {
			form = !!finding.form ? JSON.parse(finding?.form) : []
		} catch {
			form = []
		}
		setForm(form)
	}, [finding])

	function updateField(new_field) {
		const new_form = [...form]
		const new_field_index = new_form.findIndex(field => field.id === new_field.id)
		new_form[new_field_index] = new_field
		setForm(new_form)
	}

	async function onUpdate() {
		const body = { form }
		onSubmit(body)
		onClose()
	}

	const parsedForm = setupForm(form)

	return (
		<Dialog open={open} onClose={onClose} fullScreen>
			<Box sx={full_dialog_style}>
				<Box sx={inline_space_title}>
					<Typography variant='h1'>{t('actions.assignFinding')}</Typography>
					<IconButton onClick={onClose}>
						<Clear />
					</IconButton>
				</Box>
				<Typography variant='h4' sx={title_style} >{finding.free_description}</Typography>
				<MainInfo finding={finding} crit={crit} urls={urls} />
				<Divider sx={text_space} />
				<Typography variant='h4' sx={text_space} >{t('titles.additionalInfoToComplete')}:</Typography>
				<FindingFormRender form={parsedForm} onChangeForm={updateField} />
				<Box sx={end_buttons}>
					<Button variant="outlined" color="info" endIcon={<PersonAddOutlined />} onClick={onUpdate}>
						{t('actions.saveFindingAndExit')}
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}

export default ExtraFieldsDialog