import { Box, Typography } from '@mui/material'
import { inline_buttons } from '../../../../Utils/defaultStyles';
import MultipleImageContainer from '../../../../Shared/Containers/MultipleImageContainer';

export function InfoRow({ icon: Icon, label, value, color }) {
	return (
		<Box sx={{ ...inline_buttons, margin: '0 0 3px 0' }}>
			{Icon && <Icon />}
			<Typography variant='subtitle1'>{label}:</Typography>
			<Typography variant='subtitle2' color={color}>{value}</Typography>
		</Box>
	)
}

export function ImageSection({ images, baseUrl, alt }) {
	return (
		<Box sx={{ margin: '8px 0' }}>
			<MultipleImageContainer border="12px" images={images} base_url={baseUrl} alt={alt} height="64px" width="64px" align="left" />
		</Box>
	)
}
