import { italic_link, dialog_style, elliptical } from "../../Utils/defaultStyles"
import { Typography, Box, Dialog } from "@mui/material"
import useToggle from "../../Hooks/ToogleHook"
import { Clear } from "@material-ui/icons"
import { IconButton } from "@material-ui/core"
import { inline_title_no_wrap } from "../../Utils/defaultStyles"
import ImageContainer from "../../Shared/Containers/ImageContainer"
import { completePath } from "../../Utils/functions"
import newTheme from "../../newTheme"
import { useTranslation } from "react-i18next"

const css = {
	img: {
		background: newTheme.palette.blue.transparent,
		justifyContent: "center",
		padding: 3,
		alignItems: "center",
	},
	container: {
		maxHeight: "400px",
		overflowY: "auto",
		justifyContent: "center",
		alignItems: "center",
	}
}

function FindingImages({ value, as_string }) {

	const [openDialog, toggleDialog] = useToggle(false)
	const hasImages = value && value.length > 0
	const images = value?.split("&#&") || []
	const oldUrl = `${process.env.REACT_APP_IMG_URL}${process.env.REACT_APP_IMG_FOLDER}`
	const allImagesString = images.join(" ")
	const { t } = useTranslation("findings")
	function goToImage(url) {
		window.open(url, '_blank')
	}
	if (as_string) return allImagesString
	if (!value) return null
	return (
		<Box>
			{hasImages &&
				<Typography onClick={toggleDialog} sx={italic_link}>
					{t('finding.actions.openImages')}
				</Typography>
			}
			<Dialog open={openDialog} onClose={toggleDialog} fullWidth maxWidth="sm"  >
				<Box sx={dialog_style} >
					<Box sx={inline_title_no_wrap}>
						<Typography variant='h1'>{t('titles.findingImages')}</Typography>
						<IconButton onClick={toggleDialog}>
							<Clear />
						</IconButton>
					</Box>
					<Box sx={css.container}>
						{images.map((imageUrl, index) => {
							return (
								<Box sx={css.img} background={newTheme.palette.background} marginBottom={5} >
									<Typography sx={{ ...italic_link, ...elliptical }} marginBottom={3} onClick={() => goToImage(imageUrl)}>{t('finding.actions.openImage')}</Typography>
									<ImageContainer key={index} src={completePath(imageUrl, oldUrl)} height={200} width={"100%"} border={2} />
								</Box>
							)
						})}
					</Box>

				</Box>
			</Dialog>
		</Box>
	);
}

export default FindingImages