import { Box } from '@mui/material'
import BigTooltip from '../Tooltip/BigTooltip'
import CommitteeIcon from './CommitteeIcon'
import { useTranslation } from 'react-i18next'

function CommitteeDisclaimer() {
	const { t } = useTranslation('icons')
	return (
		<BigTooltip title={t('committee')}>
			<Box>
				<CommitteeIcon color="celeste" />
			</Box>
		</BigTooltip>
	)
}

export default CommitteeDisclaimer