import { Box } from '@mui/material'
import FixedHeightElement from './FixedHeightElement'
import { useEffect, useMemo, useRef, useState } from 'react'

const css = {
	scroll_container: {
		overflow: 'auto'
	},
	visible_box: {
		position: 'relative'
	}
}

// Fixed height of each element = 58px
// 58px * 100 = 5800px (total height of all elements)

function ScrollContainer({ height = 58, spacing = 12, children, maxHeight = 400, ref_element, onScrollEnd }) {

	const container = useRef(null)
	const [start_item, setStartItem] = useState(0)
	const [last_item, setLastItem] = useState(Math.floor((children.length + 1) / height))
	const [heights, setHeights] = useState({})

	useEffect(() => {

		function onScroll(e) {
			const { scrollTop, clientHeight, scrollHeight } = e.target

			let size_so_far = 0
			let first_visible_item = 0
			let last_visible_item = 0

			for (let i = 0; i < children.length; i++) {
				const item_height = heights[i] || height
				size_so_far += item_height + spacing

				if (size_so_far > scrollTop) {
					first_visible_item = i
					break
				}
			}

			size_so_far = 0
			for (let i = first_visible_item; i < children.length; i++) {
				const item_height = heights[i] || height
				size_so_far += item_height + spacing

				if (size_so_far >= clientHeight) {
					last_visible_item = i - 1
					break
				}

				if (i === children.length - 1) {
					last_visible_item = i
				}
			}
			setStartItem(first_visible_item)
			setLastItem(last_visible_item)
			// Detectar si el usuario ha llegado al final del scroll
			if (scrollTop + clientHeight >= scrollHeight - 50) {
				if (onScrollEnd) {
					onScrollEnd()
				}
			}
		}

		const containerElement = container.current
		containerElement.addEventListener('scroll', onScroll)
		if (containerElement) { onScroll({ target: containerElement }) }
		return () => containerElement.removeEventListener('scroll', onScroll)
	}, [container, height, spacing, heights, children])

	function updateHeight(index, height) {
		setHeights(h => ({ ...h, [index]: height }))
	}

	const fullContainerHeight = useMemo(() => {
		let total_height = 0
		for (let i = 0; i < children.length; i++) {
			const item_height = heights[i] || height
			total_height += item_height + spacing
		}
		return total_height
	}, [heights, children, spacing, height])

	return (
		<Box sx={css.container} ref={ref_element}>
			<Box sx={css.scroll_container} ref={container} style={{ maxHeight }} >
				<Box sx={css.visible_box} style={{ height: fullContainerHeight }}>
					{children.slice(start_item, last_item + 4).map((child, index) =>
						<FixedHeightElement spacing={spacing} key={index + start_item} height={height} index={index + start_item} updateHeight={updateHeight} heights={heights}>
							{child}
						</FixedHeightElement>
					)}
				</Box>
			</Box>
		</Box>
	)
}

export default ScrollContainer