import InnerTag from './InnerTag'
import { Box } from '@mui/material'

const css = {
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}


function TagCell({ value, as_string }) {
  if (as_string) return value.map(tag => tag.name).join("-")
  if (!value) return null
  return (
    <Box sx={css.container}>
      {value.map((tag, index) => <InnerTag tag={tag} key={tag?.name || index} />)}
    </Box>
  )
}

export default TagCell