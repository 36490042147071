import instance2 from "../Utils/instance2"

export function authLogin(body) {
	return instance2.post("/login", body)
}

export function loginEnterprise(body) {
	return instance2.post("/login_enterprise", body)
}

export function checkUsername(body) {
	return instance2.post("/check_username", body)
}

export function renewUserToken(body) {
	return instance2.post("/renew_token", body)
}

export function logout() {
	localStorage.setItem("login", "false")
	localStorage.removeItem("token")
	localStorage.removeItem("account")
	localStorage.removeItem("current_user")
	localStorage.removeItem("enterprise")
	localStorage.removeItem("branch")
	localStorage.removeItem("branch_id")
	const logoutEvent = new CustomEvent("logout")
	window.dispatchEvent(logoutEvent)
	const updateAccountEvent = new CustomEvent("update_account")
	window.dispatchEvent(updateAccountEvent)
}