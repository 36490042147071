import instance2 from "../Utils/instance2"

export function getSubEnterprises() {
	return instance2.get('sub_enterprises')
}

export function editSubEnterprise(body) {
	return instance2.put('sub_enterprises', body)
}

export function createSubEnterprise(body) {
	return instance2.post('sub_enterprises', body)
}

export const changeOrderRule = () => {
	return instance2.put('enterprises/order')
}

export const getOrderRule = () => {
	return instance2.get('enterprises/order')
}

export const getWelcome = body => {
	return instance2.get('enterprises/welcome', { params: body })
}

export const setWelcome = body => {
	return instance2.put('enterprises/welcome', body)
}

export const csv_to_excel = body => {
	return instance2.post('excel', body, { responseType: 'blob' })
}

export const getFastFindingStatus = () => {
	return instance2.get('enterprises/fast_findings')
}

export const setFastFindingStatus = () => {
	return instance2.put('enterprises/fast_findings')
}

export const getFastFindingInfo = () => {
	return instance2.get('enterprises/fast_findings/config')
}

export const getCrits = () => {
	return instance2.get('enterprises/crits')
}

export const editIPAFormat = body => {
	return instance2.put('enterprises/IPA', body)
}

export const editCrit = body => {
	return instance2.put('enterprises/crits', body)
}

export const getCritsNotifiedUsers = () => {
	return instance2.get('enterprises/crits/notified')
}

export const setCritsNotifiedUsers = body => {
	return instance2.put('enterprises/crits/notified', body)
}

export function getEnterprise() {
	return instance2.get('enterprises')
}

export function updateEnterprise(body) {
	return instance2.put('enterprises', body)
}

export function checkEpp() {
	return instance2("enterprises/check_epp")
}

export function updateBukInfo(body) {
	return instance2.put('enterprises/buk', body)
}

export function getBukUserJsonStructure(body) {
	return instance2.get('enterprises/buk/data', { params: body })
}

export function checkBukActiveModule() {
	return instance2.get('enterprises/check_buk')
}

export function getHHFactor() {
	return instance2.get('enterprises/hh_factor')
}

export function updateHHFactor(body) {
	return instance2.put('enterprises/hh_factor', body)
}

export async function checkContractorModule() {
	const response = await getEnterprise()
	const enterprise = response.data.info
	return enterprise?.contractors_module
}