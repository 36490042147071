import { useState, useEffect, useMemo } from "react"
import { Box, Dialog, Fab } from "@mui/material"
import { RotateRight } from "@mui/icons-material"

const css = {
	img: {
		borderRadius: "50%",
	},
	floating: {
		position: "fixed",
		top: 24,
		right: 24,
	},
	container: {
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		cursor: "pointer",
	}
}

const videos = ["mp4", "ogg"]

function ImageContainer({ height = 64, width = 64, src = "", alt = "alt", border = "50%" }) {
	const [angle, setAngle] = useState(0)
	const [open, setOpen] = useState(false)
	const extension = src.split(".").pop().toLowerCase()
	const isVideo = videos.includes(extension)

	let image = src
	image = useMemo(() => {
		if (image.includes("data:image")) {
			return src.split("|&|")[0]
		}
		return src
	}, [src])

	useEffect(() => {
		let image = src
		if (image.includes("data:image")) {
			image = src.split("|&|")[0]
		}
	}, [src])

	function handleOpen() {
		setOpen(!open)
	}

	function handleRotate() {
		setAngle((prevAngle) => (prevAngle + 90) % 360)
	}

	return (
		<>
			{!isVideo ? (
				<Box sx={{ ...css.container, maxWidth: width }}>
					<img
						src={image}
						style={{ borderRadius: border, height: "auto", maxWidth: "100%" }}
						alt={alt}
						onClick={handleOpen}
					/>
				</Box>
			) : (
				<video width={width} height={height} onClick={handleOpen} style={css.img}>
					<source src={image} type={`video/${extension}`} />
				</video>
			)}
			<Dialog open={open} onClose={handleOpen}>
				<Box sx={css.floating}>
					<Fab onClick={handleRotate}>
						<RotateRight />
					</Fab>
				</Box>
				<Box sx={{ overflow: "auto" }}>
					<Box sx={{ transform: `rotate(${angle}deg)` }}>
						{!isVideo ? (
							<img src={image} alt={alt} style={{ width: "100%", height: "auto" }} />
						) : (
							<video controls style={{ width: "100%" }}>
								<source src={image} type={`video/${extension}`} />
							</video>
						)}
					</Box>
				</Box>
			</Dialog>
		</>
	)
}

export default ImageContainer