import { Box } from '@mui/material'
import EntityTitle from '../EntityTitle'

function CommitteeCell({ value, element, as_string }) {
	if (as_string) return value
	const isCommittee = element?.is_committee
	return (
		<Box>
			<EntityTitle title={value} belongs={isCommittee} variant="subtitle1" />
		</Box>
	)
}

export default CommitteeCell