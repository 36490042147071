import newTheme from "../newTheme"

export const flex_center = {
	display: 'flex',
	alignItems: 'center',
}

export const inline_space = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: 2
}

export const inline_space_no_wrap = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}

export const inline_space_start = {
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: 2
}

export const inline_space_start_no_wrap = {
	display: 'flex',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	gap: 2
}

export const inline_space_end = {
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: 2
}

export const inline_space_end_no_wrap = {
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'space-between',
	gap: 2
}

export const inline_space_title = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	flexWrap: 'wrap',
	gap: 2,
	marginBottom: 2
}

export const inline_title_no_wrap = {
	display: 'flex',
	alignItems: 'center',
	gap: 2,
	marginBottom: 2,
	flexWrap: 'nowrap',
	justifyContent: 'space-between',
}

export const inline = {
	display: 'flex',
	alignItems: 'center',
	gap: 2
}

export const inline_align_end = {
	display: 'flex',
	alignItems: 'flex-end',
	flexWrap: 'wrap',
	gap: 2
}

export const inline_align_start = {
	display: 'flex',
	alignItems: 'flex-start',
	flexWrap: 'wrap',
	gap: 2
}

export const inline_title = {
	display: 'flex',
	alignItems: 'center',
	gap: 2,
	marginBottom: 2
}

export const inline_buttons = {
	display: 'flex',
	alignItems: 'center',
	gap: 1
}

export const inline_buttons_wrap = {
	display: 'flex',
	alignItems: 'center',
	gap: 1,
	flexWrap: 'wrap'
}

export const text_space = {
	margin: '12px 0'
}

export const end_buttons = {
	textAlign: 'end'
}

export const end_flex_buttons = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	gap: 2
}

export const button_position = {
	position: 'absolute',
	top: 6,
	right: 6
}

export const labeless = {
	marginBottom: -2
}

export const dialog_style = {
	padding: 3
}

export const full_dialog_style = {
	padding: 3,
	maxWidth: 600,
	width: '100%',
	margin: '0 auto',
	boxSizing: 'border-box'
}

export const link_style = {
	color: newTheme.palette.blue.main,
	textDecoration: 'underline',
	cursor: 'pointer'
}

export const underline_style = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textDecoration: 'underline',
	cursor: 'pointer'
}

export const clickable = {
	cursor: 'pointer',
}

export const paper_style = {
	padding: 2,
	borderRadius: 2,
	background: 'white',
	position: 'relative'
}

export const info_box_grey = {
	padding: 1,
	borderRadius: 1,
	background: newTheme.palette.background.main
}

export const info_box_white = {
	padding: 1,
	borderRadius: 1,
	background: newTheme.palette.white.main
}

export const title_style = {
	marginBottom: 2
}

export const section_separation = {
	margin: '24px 0'
}

export const italic = {
	fontStyle: 'italic'
}

export const italic_bold = {
	fontStyle: 'italic',
	fontWeight: 'bold'
}

export const elliptical = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
}

export const italic_link = {
	fontStyle: 'italic',
	color: newTheme.palette.blue.main,
	textDecoration: 'underline',
	cursor: 'pointer'
}

export const loader_container = {
	position: 'relative',
	height: 150,
}

export const mini_loader_container = {
	position: 'relative',
	height: 50,
}

export const highligh_info = {
	color: newTheme.palette.blue.main,
}

export const highligh_secondary = {
	color: newTheme.palette.secondary.main,
}

export const centered_container = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}

export const transitioner = {
	transition: 'all 0.2s ease'
}

export const tags_container = {
	display: 'flex',
	gap: 2,
	flexWrap: 'wrap',
	margin: '12px 0 24px'
}

export const tag_style = {
	padding: '3px 10px',
	borderRadius: 2,
	display: 'flex',
	alignItems: 'center',
	gap: 1,
	cursor: 'pointer',
}

export const tabs_style = {
	background: newTheme.palette.white.main,
	borderRadius: 1,
	margin: '24px 0',
}

export const view_container = {
	margin: 3
}

export const color_box = {
	height: 18,
	width: 20,
	borderRadius: 2,
	border: '1px solid grey',
	marginRight: 2
}

export function combineStyles(...styles) {
	return styles.reduce((acc, style) => {
		return { ...acc, ...style }
	}, {})
}

