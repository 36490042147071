import { Box } from '@mui/material'
import GeneralField from '../../../Shared/Fields/GeneralField'
import { text_space } from '../../../Utils/defaultStyles'


function FinishedForm({ form }) {
	return form.map(question => {
		return (
			<Box key={question.id} sx={text_space}>
				<GeneralField key={question.id} field={question} finished />
			</Box>
		)
	})
}


function FindingFormRender({ form, onChangeForm, finished }) {

	if (finished) return <FinishedForm form={form} />

	return (
		<Box>
			{form.map(field => <GeneralField key={field.id} field={field} updateField={onChangeForm} />)}
		</Box>
	)
}

export default FindingFormRender