import React from 'react'
import { Box } from '@mui/material'
import './animations.css'

function LoaderAnimator() {
  return (
    <Box className="lds-ring">
      <Box />
      <Box />
      <Box />
      <Box />
    </Box>
  )
}

export default LoaderAnimator