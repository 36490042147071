import { Box, Checkbox, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

const css = {
	container: {
		borderRadius: 1,
		display: 'flex',
		gap: 1,
		alignItems: 'center',
		position: 'absolute',
		width: '100%',
		boxSizing: 'border-box',
		overflow: 'hidden',
	}
}

function FixedHeightElement({ height = 40, index, spacing = 12, children, updateHeight, heights }) {

	const [top, setTop] = useState(0)

	const el = useRef(null)

	useEffect(() => {
		let height_to_this_point = 0
		for (let i = 0; i < index; i++) {
			const item_height = heights[i] || height
			height_to_this_point += item_height + spacing
		}
		const top = height_to_this_point
		setTop(top)
	}, [heights])

	useEffect(() => {
		if (!!el.current) {
			const height = el.current.getBoundingClientRect().height
			console.log({ height })
			const differentHeight = heights[index] !== height
			if (differentHeight) updateHeight(index, height)
		}
	}, [children, el.current])


	return (
		<Box sx={{ ...css.container }} style={{ top }} ref={el}>
			{children}
		</Box>
	)
}

export default FixedHeightElement