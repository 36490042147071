import { Box } from '@mui/material'
import { createContext, useContext, useEffect, useState } from 'react'
import { getCurrentUser } from './API/users'
import AppContext from './AppContext'
import { permissions_dict } from './Components/Workers/Info'
import { getEnterprise } from './API/enterprise'
import { isValidJson } from './Utils/functions'

export const CurrentUserContext = createContext({})

function saveCurrentUser(user) {
	localStorage.setItem("current_user", JSON.stringify(user))
}

function fetchCurrentUser() {
	const localUser = localStorage.getItem("current_user")
	const user = localUser && isValidJson(localUser) ? JSON.parse(localUser) : {}
	return user
}

function CurrentUser({ children }) {
	const [user, setUser] = useState({})
	const [enterprise, setEnterprise] = useState({})
	const { account, branch_id } = useContext(AppContext)

	useEffect(() => {
		const localUser = fetchCurrentUser()
		if (localUser) setUser(localUser)
	}, [])

	useEffect(() => {
		async function fetchUser() {
			if (!!account?.id) {
				const response = await getCurrentUser()
				console.log(`Fetched current user ${response.data.info.name}`)
				setUser(response.data.info)
				saveCurrentUser(response.data.info)
			}
		}
		fetchUser()
	}, [account, branch_id])

	useEffect(() => {
		async function fetchEnterprise() {
			const response = await getEnterprise()
			setEnterprise(response.data.info)
		}
		fetchEnterprise()
	}, [user])

	async function fetchUser() {
		const response = await getCurrentUser()
		setUser(response.data.info)
		saveCurrentUser(response.data.info)
	}

	function checkPermissions(expectedPermissions, plain = false) {
		const permissions = user.permissions || ""
		const restrictions = permissions.split(",")
		let expectedArray = Array.isArray(expectedPermissions) ? expectedPermissions : [expectedPermissions]
		if (plain) {
			expectedArray = expectedArray.map(permission => permissions_dict[permission])
		}
		return expectedArray.every(permission => !restrictions.includes(permission))
	}

	function filterCurrentUserBranches(branches) {
		const currentUserBranches = user.branch_ids || []
		const userBranches = branches.filter(branch => currentUserBranches.includes(parseInt(branch.id)))
		return userBranches
	}

	return (
		<Box>
			<CurrentUserContext.Provider value={{
				checkPermissions,
				currentUser: user,
				setCurrentUser: setUser,
				fetchCurrentUser: fetchUser,
				isAdmin: user.user_type_id === 1,
				isContractor: !!user.contractor_id,
				enterprise,
				setEnterprise,
				filterCurrentUserBranches
			}}>
				{children}
			</CurrentUserContext.Provider>
		</Box>
	)
}

export default CurrentUser